import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const SERVICES_URL = "v1/admin/profiles/:id/services";
export const SERVICES_AND_CATEGORIES_URL = "v1/admin/profiles/:id/services-and-categories";
export const SERVICE_RESOURCES_URL = "v1/admin/services/:id/resources";
export const SERVICE_ADDONS_URL = "v1/admin/services/:id/addons";
export const SERVICE_OPTIONS_URL = "v1/admin/profiles/:id/services/options";
export const SERVICE_VISIBILITY_URL = "v1/admin/services/:id/visibility";
export const SERVICE_SORT_URL = "v1/admin/profiles/:id/services/sort";

export const APPOINTMENT_URL = "v1/admin/appointments/:id";
export const ADD_APPOINTMENT_URL = "v1/admin/profiles/:id/services/appointments";
export const APPOINTMENT_RESOURCES_URL = "v1/admin/appointments/:id/resources";
export const APPOINTMENT_BOOKINGS_URL = "v1/admin/appointments/:id/bookings";

export const ADD_COURSE_URL = "v1/admin/profiles/:id/services/courses";
export const COURSE_URL = "v1/admin/courses/:id";
export const COURSE_RESOURCES_URL = "v1/admin/courses/:id/resources";
export const COURSE_ATTENDEES_URL = "v1/admin/courses/:id/attendees";
export const COURSE_OCCASIONS_URL = "v1/admin/courses/:id/occasions";

export const COURSE_WAITING_LIST_MEMBERS_URL = "v1/admin/courses/:id/waiting-list/members";
export const WAITING_LIST_MEMBERS_URL = "v1/admin/waiting-list-members/:id";
export const WAITING_LIST_MEMBERS_ACCEPT_URL = "v1/admin/waiting-list-members/:id/accept";

export const MEMBERSHIP_URL = "v1/admin/memberships/:id";
export const ADD_MEMBERSHIP_URL = "v1/admin/profiles/:id/services/memberships";
export const MEMBERSHIP_SERVICES_URL = "v1/admin/memberships/:id/services";
export const MEMBERSHIP_BOOKINGS_URL = "v1/admin/memberships/:id/bookings";

export const VOUCHER_URL = "v1/admin/vouchers/:id";
export const ADD_VOUCHER_URL = "v1/admin/profiles/:id/services/vouchers";
export const VOUCHER_SERVICES_URL = "v1/admin/vouchers/:id/services";
export const VOUCHER_BOOKINGS_URL = "v1/admin/vouchers/:id/bookings";

export const PRODUCT_URL = "v1/admin/products/:id";
export const ADD_PRODUCT_URL = "v1/admin/profiles/:id/services/products";

export function getServices(profileId, page, perPage, search, status, type) {
    const url =
        SERVICES_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (type ? "&type=" + type : "");
    return axios.get(getApiUrl() + url);
}

export function getServicesAndCategories(profileId, page, perPage, search, status) {
    const url =
        SERVICES_AND_CATEGORIES_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "");
    return axios.get(getApiUrl() + url);
}

export function getAppointment(serviceId) {
    const url = APPOINTMENT_URL.replace(":id", serviceId);
    return axios.get(getApiUrl() + url);
}

export function addAppointment(profileId, data) {
    return axios.post(getApiUrl() + ADD_APPOINTMENT_URL.replace(":id", profileId), data);
}

export function patchAppointment(serviceId, originalAppointment, changedAppointment) {
    const url = APPOINTMENT_URL.replace(":id", serviceId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalAppointment, changedAppointment), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteAppointment(serviceId) {
    return axios.delete(getApiUrl() + APPOINTMENT_URL.replace(":id", serviceId));
}

export function getAppointmentResources(serviceId, page, perPage, status) {
    const url =
        APPOINTMENT_RESOURCES_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (status ? `&status=${status}` : "");
    return axios.get(getApiUrl() + url);
}

export function addAppointmentResource(serviceId, data) {
    return axios.post(getApiUrl() + APPOINTMENT_RESOURCES_URL.replace(":id", serviceId), data);
}

export function deleteAppointmentResource(serviceId, serviceResourceId) {
    return axios.delete(getApiUrl() + APPOINTMENT_RESOURCES_URL.replace(":id", serviceId) + "/" + serviceResourceId);
}

export function getAppointmentBookings(serviceId, page, perPage, status) {
    const url =
        APPOINTMENT_BOOKINGS_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (status ? `&status=${status}` : "");
    return axios.get(getApiUrl() + url);
}

export function getCourse(serviceId) {
    const url = COURSE_URL.replace(":id", serviceId);
    return axios.get(getApiUrl() + url);
}

export function getServiceResources(serviceId, page, perPage, search) {
    const url =
        SERVICE_RESOURCES_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "");
    return axios.get(getApiUrl() + url);
}

export function getServiceAddons(serviceId, page, perPage, search, type) {
    const url =
        SERVICE_ADDONS_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (type ? "&type=" + type : "");
    return axios.get(getApiUrl() + url);
}

export function addServiceAddon(serviceId, data) {
    return axios.post(getApiUrl() + SERVICE_ADDONS_URL.replace(":id", serviceId), data);
}

export function deleteServiceAddon(serviceId, serviceAddonId) {
    return axios.delete(getApiUrl() + SERVICE_ADDONS_URL.replace(":id", serviceId) + "/" + serviceAddonId);
}

export function getServiceOptions(profileId) {
    const url = SERVICE_OPTIONS_URL.replace(":id", profileId);
    return axios.get(getApiUrl() + url);
}

export function updateServiceVisibility(serviceId, isVisible) {
    const url = SERVICE_VISIBILITY_URL.replace(":id", serviceId);
    return axios.put(getApiUrl() + url, { isVisible });
}

export function addCourse(profileId, data) {
    return axios.post(getApiUrl() + ADD_COURSE_URL.replace(":id", profileId), data);
}

export function patchCourse(serviceId, originalCourse, changedCourse) {
    const url = COURSE_URL.replace(":id", serviceId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalCourse, changedCourse), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteCourse(serviceId) {
    return axios.delete(getApiUrl() + COURSE_URL.replace(":id", serviceId));
}

export function getCourseResources(serviceId, page, perPage, status) {
    const url =
        COURSE_RESOURCES_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (status ? `&status=${status}` : "");
    return axios.get(getApiUrl() + url);
}

export function deleteCourseResource(serviceId, attendeeId) {
    return axios.delete(getApiUrl() + COURSE_RESOURCES_URL.replace(":id", serviceId) + "/" + attendeeId);
}

export function addCourseResource(serviceId, data) {
    return axios.post(getApiUrl() + COURSE_RESOURCES_URL.replace(":id", serviceId), data);
}

export function getCourseAttendees(serviceId, page, perPage, status) {
    const url =
        COURSE_ATTENDEES_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (status ? `&status=${status}` : "");
    return axios.get(getApiUrl() + url);
}

export function deleteCourseAttendee(serviceId, attendeeId) {
    return axios.delete(getApiUrl() + COURSE_ATTENDEES_URL.replace(":id", serviceId) + "/" + attendeeId);
}

export function getCourseOccasions(serviceId, page, perPage, status) {
    const url =
        COURSE_OCCASIONS_URL.replace(":id", serviceId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (status ? `&status=${status}` : "");
    return axios.get(getApiUrl() + url);
}

export function addCourseOccasions(serviceId, data) {
    return axios.post(getApiUrl() + COURSE_OCCASIONS_URL.replace(":id", serviceId), data);
}

export function getWaitingListMembers(serviceId, page, perPage) {
    const url = COURSE_WAITING_LIST_MEMBERS_URL.replace(":id", serviceId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function acceptWaitingListMember(waitingListMemberId, data) {
    return axios.post(getApiUrl() + WAITING_LIST_MEMBERS_ACCEPT_URL.replace(":id", waitingListMemberId), data);
}

export function deleteWaitingListMember(waitingListMemberId) {
    const url = WAITING_LIST_MEMBERS_URL.replace(":id", waitingListMemberId);
    return axios.delete(getApiUrl() + url);
}

export function getMembership(serviceId) {
    const url = MEMBERSHIP_URL.replace(":id", serviceId);
    return axios.get(getApiUrl() + url);
}

export function addMembership(profileId, data) {
    return axios.post(getApiUrl() + ADD_MEMBERSHIP_URL.replace(":id", profileId), data);
}

export function patchMembership(serviceId, originalMembership, changedMembership) {
    const url = MEMBERSHIP_URL.replace(":id", serviceId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalMembership, changedMembership), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteMembership(serviceId) {
    return axios.delete(getApiUrl() + MEMBERSHIP_URL.replace(":id", serviceId));
}

export function getMembershipServices(serviceId, page, perPage) {
    const url = MEMBERSHIP_SERVICES_URL.replace(":id", serviceId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function addMembershipService(serviceId, data) {
    return axios.post(getApiUrl() + MEMBERSHIP_SERVICES_URL.replace(":id", serviceId), data);
}

export function deleteMembershipService(serviceId, offerServiceId) {
    return axios.delete(getApiUrl() + MEMBERSHIP_SERVICES_URL.replace(":id", serviceId) + "/" + offerServiceId);
}

export function getMembershipBookings(serviceId, page, perPage) {
    const url = MEMBERSHIP_BOOKINGS_URL.replace(":id", serviceId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getVoucher(serviceId) {
    const url = VOUCHER_URL.replace(":id", serviceId);
    return axios.get(getApiUrl() + url);
}

export function addVoucher(profileId, data) {
    return axios.post(getApiUrl() + ADD_VOUCHER_URL.replace(":id", profileId), data);
}

export function patchVoucher(serviceId, originalVoucher, changedVoucher) {
    const url = VOUCHER_URL.replace(":id", serviceId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalVoucher, changedVoucher), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteVoucher(serviceId) {
    return axios.delete(getApiUrl() + VOUCHER_URL.replace(":id", serviceId));
}

export function getVoucherServices(serviceId, page, perPage) {
    const url = VOUCHER_SERVICES_URL.replace(":id", serviceId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function addVoucherService(serviceId, data) {
    return axios.post(getApiUrl() + VOUCHER_SERVICES_URL.replace(":id", serviceId), data);
}

export function deleteVoucherService(serviceId, offerServiceId) {
    return axios.delete(getApiUrl() + VOUCHER_SERVICES_URL.replace(":id", serviceId) + "/" + offerServiceId);
}

export function getVoucherBookings(serviceId, page, perPage) {
    const url = VOUCHER_BOOKINGS_URL.replace(":id", serviceId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getProduct(serviceId) {
    const url = PRODUCT_URL.replace(":id", serviceId);
    return axios.get(getApiUrl() + url);
}

export function addProduct(profileId, data) {
    return axios.post(getApiUrl() + ADD_PRODUCT_URL.replace(":id", profileId), data);
}

export function patchProduct(serviceId, originalProduct, changedProduct) {
    const url = PRODUCT_URL.replace(":id", serviceId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalProduct, changedProduct), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteProduct(serviceId) {
    return axios.delete(getApiUrl() + PRODUCT_URL.replace(":id", serviceId));
}

export function updateServiceListSorting(profileId, data) {
    return axios.post(getApiUrl() + SERVICE_SORT_URL.replace(":id", profileId), data);
}
