import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import * as bookingUtils from "./utils";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import ChipWarning from "../../../components/chips/ChipWarning";
import ChipError from "../../../components/chips/ChipError";
import { BOOKING_STATUS_UNCONFIRMED } from "./utils";
import { isAtLeastStaffPermission } from "../../resources/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(2),
        alignItems: "center",
    },
}));

function BookingPageNavigator({ bookingId }) {
    const { profile } = useSelector((state) => state.auth);
    const { booking } = useSelector((state) => state.bookings);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {booking && (
                            <>
                                <span className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1">
                                    <FormattedMessage id="BOOKING.DETAILS.TITLE_NAVIGATOR" values={{ id: bookingId }} />
                                </span>
                                {booking.status === bookingUtils.BOOKING_STATUS_CANCELLED && (
                                    <ChipError label={<FormattedMessage id="BOOKING.STATUS.CANCELLED" />} />
                                )}
                                {booking.status === bookingUtils.BOOKING_STATUS_DELETED && (
                                    <ChipError label={<FormattedMessage id="BOOKING.STATUS.DELETED" />} />
                                )}
                                {booking.status === bookingUtils.BOOKING_STATUS_UNCONFIRMED && (
                                    <ChipWarning label={<FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />} />
                                )}
                                {booking.status === bookingUtils.BOOKING_STATUS_CONFIRMED && (
                                    <ChipSuccess label={<FormattedMessage id="BOOKING.STATUS.CONFIRMED" />} />
                                )}
                            </>
                        )}
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="BOOKING.DETAILS.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(booking?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                        {booking?.createdBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.CREATED_BY" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${booking.createdBy.firstName} ${booking.createdBy.surname}`}
                                </span>
                            </div>
                        )}
                        {booking?.customer && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.CUSTOMER" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/customers/${booking.customer.id}`}>
                                        {`${booking.customer.firstName} ${booking.customer.surname}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {booking && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.PRICE" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${booking.price} ${booking.currency}`}
                                </span>
                            </div>
                        )}
                        {booking?.payment?.transaction && isAtLeastStaffPermission(profile.permissionId) && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.TRANSACTION" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/transactions/${booking.payment.transaction.id}`}>
                                        {booking.payment.transaction.id}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {booking?.checkout?.promotionCode && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.PROMOTION_CODE" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/promotion-codes/${booking.checkout.promotionCode.id}`}>
                                        {`${booking.checkout.promotionCode.code}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {booking?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.CANCELLED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(booking.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {booking?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="BOOKING.DETAILS.CANCELLED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${booking.deletedBy.firstName} ${booking.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/bookings/${bookingId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="BOOKING.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/bookings/${bookingId}/checkout`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/shopping/Bag1.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="BOOKING.CHECKOUT.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/bookings/${bookingId}/staff-details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="BOOKING.STAFF_DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/bookings/${bookingId}/accesses`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="BOOKING.ACCESSES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {booking?.status === BOOKING_STATUS_UNCONFIRMED && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/bookings/${bookingId}/payment-link`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/shopping/Dollar.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="BOOKING.TRANSACTION_LINK.TITLE" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
        </div>
    );
}

export default connect(null, bookingActions)(BookingPageNavigator);
