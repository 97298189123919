import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    avatar: {
        height: "90px",
        width: "90px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: "0.42rem",
    },
    avatarInfoCell: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function SettingsPageNavigator() {
    const { profile } = useSelector((state) => state.profiles);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end"></div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className="d-flex">
                        <div>
                            <div
                                className={classes.avatar}
                                style={{
                                    backgroundImage: `url(${profile?.avatarUrl})`,
                                }}
                            ></div>
                        </div>
                        <div className={classes.avatarInfoCell}>
                            <div>
                                {profile?.name && (
                                    <span className="font-weight-bolder font-size-h6 text-dark-75">
                                        {`${profile?.name}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="PROFILE.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(profile?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink to={`/settings/details`} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink to={`/settings/bookings`} className="navi-link py-4" activeClassName="active">
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.BOOKING_SETTINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/settings/billing-templates`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.BILLING_TEMPLATES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/settings/pricing-templates`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.PRICING_TEMPLATES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/settings/reminder-templates`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/settings/cancellation-templates`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PROFILE.CANCELLATION_TEMPLATES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
        </div>
    );
}

export default connect(null, customerActions)(SettingsPageNavigator);
