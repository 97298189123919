import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Paper, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import {
    LinkCell,
    AvatarCell,
    DateTimeCell,
    TextCell,
    ChipCell,
    TranslationCell,
    TextCellEllipsis,
    LinkCellEllipsis,
} from "../../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import * as bookingUtils from "./utils";
import BookingListToolbar from "../../../components/tables/toolbar/BookingListToolbar";
import ContentDrawer from "../../../components/drawer/ContentDrawer";
import * as drawerUtils from "../../../components/drawer/utils";
import DrawerAddBooking from "../DrawerAddBooking";
import { getServiceDetailsPageUrl, SERVICE_TYPE_VOUCHER, SERVICE_TYPE_MEMBERSHIP } from "../../services/utils";
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_SWISH, PAYMENT_TYPE_INVOICE } from "../../transactions/utils";
import { isAtLeastStaffPermission, isAtLeastBookingPermission } from "../../resources/utils";

const drawerWidth = drawerUtils.getDrawerDefaultWidth();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    content: {
        flexGrow: 1,
    },
}));

function BookingListPage({ getBookings, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, bookingsPagination } = useSelector((state) => state.bookings);
    const { displayError } = useSelector((state) => state.errors);

    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [paymentType, setPaymentType] = useState("");

    const contentContainerRef = useRef(null);

    // Determine if small screen for the sake of drawer behaviour (push main content or go above)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getBookings(profile.id, pageIndex + 1, pageSize, search, status, paymentType);
        },
        [getBookings, profile.id, search, status, paymentType]
    );

    const onDrawerToggled = (open) => {
        setIsDrawerOpen(open);
    };

    const onSearchChanged = useCallback((value) => {
        setSearch(value);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onPaymentTypeChanged = useCallback((value) => {
        setPaymentType(value);
    }, []);

    const onAddBookingClicked = () => {
        setIsDrawerOpen(true);
    };

    return (
        <div className={classes.root} ref={contentContainerRef}>
            <Paper
                className={classes.content}
                style={
                    isSmallScreen
                        ? {}
                        : drawerUtils.getDrawerOffsetsStyle(
                              drawerWidth,
                              isDrawerOpen,
                              contentContainerRef,
                              document.getElementsByClassName("container")[0]
                          )
                }
            >
                <MaterialReactTable
                    key={profile.id}
                    data={useMemo(() => bookingsPagination.data, [bookingsPagination.data])}
                    fetchData={fetchData}
                    rowCount={bookingsPagination.totalRows}
                    isLoading={isLoading}
                    error={displayError}
                    renderToolbar={() => {
                        return (
                            <BookingListToolbar
                                onSearchChanged={onSearchChanged}
                                onStatusChanged={onStatusChanged}
                                onPaymentTypeChanged={onPaymentTypeChanged}
                                onAddBookingClicked={
                                    isAtLeastBookingPermission(profile.permissionId) ? onAddBookingClicked : null
                                }
                            />
                        );
                    }}
                    columns={useMemo(
                        () => [
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.ID" />,
                                accessor: "id",
                                width: "7%",
                                Cell: (props) => {
                                    return LinkCell("/bookings/" + props.value, props.value);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.CUSTOMER" />,
                                accessor: "customer",
                                maxWidth: 275,
                                Cell: ({ row }) => {
                                    return (
                                        <Link to={`/customers/${row.original.customer.id}`}>
                                            {AvatarCell(
                                                row.original.customer.avatarUrl,
                                                `${row.original.customer.firstName} ${row.original.customer.surname}`
                                            )}
                                        </Link>
                                    );
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.SERVICE" />,
                                accessor: "service",
                                maxWidth: 275,
                                Cell: (props) => {
                                    if (isAtLeastStaffPermission(profile.permissionId)) {
                                        const url = getServiceDetailsPageUrl(props.value.type, props.value.id);
                                        if (!url) return TextCellEllipsis(props.value.name);
                                        return LinkCellEllipsis(url, props.value.name);
                                    }

                                    return TextCellEllipsis(props.value.name);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.CREATED_AT" />,
                                accessor: "createdAt",
                                Cell: (props) => {
                                    return DateTimeCell(props.value);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.PRICE" />,
                                accessor: "price",
                                Cell: (props) => {
                                    return TextCell(props.value + " " + props.row.original.currency);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.PAYMENT_METHOD" />,
                                accessor: "payment",
                                Cell: (props) => {
                                    const payment = props.value;
                                    if (!payment) {
                                        return TextCell();
                                    }

                                    if (payment.transaction) {
                                        if (payment.transaction.paymentType === PAYMENT_TYPE_CARD) {
                                            return TranslationCell("PAYMENT.PAYMENT_TYPE.CARD");
                                        } else if (payment.transaction.paymentType === PAYMENT_TYPE_SWISH) {
                                            return TranslationCell("PAYMENT.PAYMENT_TYPE.SWISH");
                                        } else if (payment.transaction.paymentType === PAYMENT_TYPE_INVOICE) {
                                            return TranslationCell("PAYMENT.PAYMENT_TYPE.INVOICE");
                                        }
                                    } else if (payment.offerTransactions && payment.offerTransactions.length > 0) {
                                        if (payment.offerTransactions[0].offerType === SERVICE_TYPE_VOUCHER) {
                                            return TranslationCell("SERVICE.TYPE.VOUCHER");
                                        } else if (payment.offerTransactions[0].offerType === SERVICE_TYPE_MEMBERSHIP) {
                                            return TranslationCell("SERVICE.TYPE.MEMBERSHIP");
                                        }
                                    }

                                    return TextCell();
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.LIST.HEADER.STATUS" />,
                                accessor: "status",
                                Cell: (props) => {
                                    if (props.value === bookingUtils.BOOKING_STATUS_DELETED) {
                                        return ChipCell("error", <FormattedMessage id="BOOKING.STATUS.DELETED" />);
                                    } else if (props.value === bookingUtils.BOOKING_STATUS_CANCELLED) {
                                        return ChipCell("error", <FormattedMessage id="BOOKING.STATUS.CANCELLED" />);
                                    } else if (props.value === bookingUtils.BOOKING_STATUS_UNCONFIRMED) {
                                        return ChipCell(
                                            "warning",
                                            <FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />
                                        );
                                    } else if (props.value === bookingUtils.BOOKING_STATUS_UNPAID) {
                                        return ChipCell("warning", <FormattedMessage id="BOOKING.STATUS.UNPAID" />);
                                    } else if (props.value === bookingUtils.BOOKING_STATUS_CONFIRMED) {
                                        return ChipCell("success", <FormattedMessage id="BOOKING.STATUS.CONFIRMED" />);
                                    }
                                    return TextCell();
                                },
                            },
                        ],
                        [profile.permissionId]
                    )}
                />
            </Paper>

            <ContentDrawer
                isOpen={isDrawerOpen}
                onDrawerToggle={onDrawerToggled}
                width={drawerWidth}
                paperProps={{ style: { backgroundColor: "#FAFAFA" } }}
            >
                {isAtLeastBookingPermission(profile.permissionId) && (
                    <DrawerAddBooking
                        onDrawerToggle={onDrawerToggled}
                        onBookingAdded={() => getBookings(profile.id, 1, 10)}
                    />
                )}
            </ContentDrawer>
        </div>
    );
}

export default connect(null, { ...bookingActions, ...errorActions })(BookingListPage);
