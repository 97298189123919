import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Avatar } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Row, Col } from "react-bootstrap";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getResourcesAndGroupsOptions } from "../../resources/utils";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {},
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

function AppointmentResourceAddModal({ onResourceAdded, getResourcesAndGroups, onCloseClicked, show, isLoading }) {
    const classes = useStyles();
    const intl = useIntl();

    const { profile } = useSelector((state) => state.auth);
    const { resourcesAndGroupsListPagination, isLoading: isResourcesLoading } = useSelector((state) => state.resources);

    const profileId = profile.id;

    const [chosenResource, setChosenResource] = useState(null);

    useEffect(() => {
        getResourcesAndGroups(profileId, 1, 20);
    }, [profileId, getResourcesAndGroups]);

    const onSaveClicked = () => {
        if (!chosenResource) return;

        let addResourceData = {};
        if (!chosenResource.isGroup) {
            addResourceData.resourceId = chosenResource.id;
        } else {
            addResourceData.resourceGroupId = chosenResource.id;
        }

        onResourceAdded(addResourceData);
    };

    const onResourceSearch = (search) => {
        getResourcesAndGroups(profileId, 1, 20, search);
    };

    const onResourceChanged = (resources) => {
        setChosenResource(resources[0]);
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.ADD_MODAL.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.ADD_MODAL.CHOOSE_RESOURCE" />
                        </Form.Label>
                        <Row noGutters>
                            <Col xl={6}>
                                <AsyncTypeahead
                                    id="typeahead-add-course-resource"
                                    labelKey={"name"}
                                    minLength={0}
                                    onChange={onResourceChanged}
                                    onSearch={onResourceSearch}
                                    onInputChange={(input) => {
                                        if (!input) {
                                            onResourceSearch(input);
                                        }
                                    }}
                                    useCache={false}
                                    isLoading={isResourcesLoading}
                                    options={getResourcesAndGroupsOptions(resourcesAndGroupsListPagination)}
                                    emptyLabel={intl.formatMessage({
                                        id: "SERVICE.COURSE.BOOKING_SETTINGS.RESOURCES.SEARCH_EMPTY",
                                    })}
                                    renderMenuItemChildren={(option, props) => (
                                        <div className={classes.listItemResourceContainer}>
                                            {!option.isGroup && (
                                                <>
                                                    <Avatar
                                                        alt={option.name}
                                                        src={option.avatarUrl}
                                                        className={classes.listItemResourceAvatar}
                                                    />
                                                    <span>{option.name}</span>
                                                </>
                                            )}
                                            {option.isGroup && (
                                                <>
                                                    <Avatar
                                                        alt={option.name}
                                                        src={option.avatarUrl}
                                                        className={classes.listItemResourceAvatar}
                                                    >
                                                        <GroupIcon />
                                                    </Avatar>
                                                    <span>
                                                        <FormattedMessage
                                                            id="RESOURCE_AND_GROUP.LIST.GROUP.IDENTIFIER"
                                                            values={{ name: option.name }}
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    selected={chosenResource ? [chosenResource] : []}
                                    filterBy={() => true}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={() => onSaveClicked()}
                    label={<FormattedMessage id="COMMON.ADD" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, resourceActions)(AppointmentResourceAddModal);
