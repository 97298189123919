import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {
    makeStyles,
    withStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Divider,
} from "@material-ui/core";
import { Form } from "react-bootstrap";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { actions as transactionActions } from "../../../../redux/transactions/transactionRedux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import "moment/locale/sv";
import DrawerHeader from "./DrawerHeader";
import DrawerEntryTime from "./DrawerEntryTime";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import HistoryIcon from "@material-ui/icons/History";
import LinkIcon from "@material-ui/icons/Link";
import InvoiceIcon from "@material-ui/icons/Description";
import CardIcon from "@material-ui/icons/CreditCard";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import DollarIcon from "@material-ui/icons/AttachMoney";
import { getResourceDetailsUrl } from "../../../utils/ResourceUtils";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PAYMENT_MODE_ON_ARRIVAL, SERVICE_TYPE_VOUCHER, SERVICE_TYPE_MEMBERSHIP } from "../../services/utils";
import {
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PARTPAID,
    PAYMENT_STATUS_PAID,
    PAYMENT_TYPE_CARD,
    PAYMENT_TYPE_SWISH,
    PAYMENT_TYPE_INVOICE,
} from "../../transactions/utils";
import * as bookingUtils from "../bookings/utils";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import ChipWarning from "../../../components/chips/ChipWarning";
import ChipError from "../../../components/chips/ChipError";
import SelectResourceModal from "../../resources/SelectResourceModal";
import UpdateEntryModal from "./UpdateEntryModal";
import DeleteEntryModal from "./DeleteEntryModal";
import { isAtLeastStaffPermission, isAtLeastBookingPermission } from "../../resources/utils";
import { getEntryTitle, getCalendarSettings } from "../entryUtils";

const useStyles = makeStyles((theme) => ({
    entryDrawerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#FAFAFA",
        flexDirection: "column",
    },
    detailsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
        padding: theme.spacing(2),
    },
    detailsLoadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    sectionContainer: {
        marginBottom: "16px",
    },
    sectionLabel: {
        marginBottom: "4px",
    },
    accordionHeaderContainer: {
        display: "flex",
        alignItems: "center",
    },
    accordionIconHeaderLabel: {
        marginLeft: "8px",
    },
    addonListItem: {
        position: "initial",
    },
}));

const AccordionSummaryStyled = withStyles({
    root: {
        minHeight: "56px",
        "&$expanded": {
            minHeight: "56px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 0px 0px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemIconStyled = withStyles({
    root: {
        minWidth: "40px",
        justifyContent: "center",
    },
})(ListItemIcon);

const schema = yup.object().shape({
    staffNotes: yup.string(),
});

function DrawerAppointment({
    onDrawerToggle,
    showErrorMessage,
    updateEntryAppointment,
    deleteEntryAppointment,
    downloadTransactionPdf,
    onDrawerEntryDeleted,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { entry } = useSelector((state) => state.entries);
    const { isDrawerLoading } = useSelector((state) => state.bookingManager);
    const classes = useStyles(entry);
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showChangeResourceModal, setShowChangeResourceModal] = useState(false);
    const [changedResource, setChangedResource] = useState(null);

    const bookedResource =
        changedResource != null
            ? changedResource
            : entry.resources && entry.resources.length > 0
            ? entry.resources[0]
            : null;
    const payment = entry.booking ? entry.booking.payment : null;
    const transaction = payment ? payment.transaction : null;
    const paymentUrl = entry.booking ? entry.booking.paymentUrl : null;

    const { register, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    const getCurrentStartTime = () => {
        return selectedStartTime ? selectedStartTime : moment(entry.startTime);
    };

    const getCurrentEndTime = () => {
        return selectedEndTime ? selectedEndTime : moment(entry.endTime);
    };

    const getBookingStatusChip = () => {
        const booking = entry?.booking;
        if (!booking) {
            return <></>;
        }

        if (booking.status === bookingUtils.BOOKING_STATUS_DELETED) {
            return <ChipError label={<FormattedMessage id="BOOKING.STATUS.DELETED" />} />;
        } else if (booking.status === bookingUtils.BOOKING_STATUS_CANCELLED) {
            return <ChipError label={<FormattedMessage id="BOOKING.STATUS.CANCELLED" />} />;
        } else if (booking.status === bookingUtils.BOOKING_STATUS_UNCONFIRMED) {
            return <ChipWarning label={<FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />} />;
        } else if (booking.status === bookingUtils.BOOKING_STATUS_UNPAID) {
            return <ChipWarning label={<FormattedMessage id="BOOKING.STATUS.UNPAID" />} />;
        } else if (booking.status === bookingUtils.BOOKING_STATUS_CONFIRMED) {
            return <ChipSuccess label={<FormattedMessage id="BOOKING.STATUS.CONFIRMED" />} />;
        }
    };

    const getPaymentStatusChip = () => {
        if (entry.service.paymentMode === PAYMENT_MODE_ON_ARRIVAL) {
            return <></>;
        }

        if (!payment) {
            if (entry.booking.price > 0) {
                return <ChipError label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.UNPAID" />} />;
            } else {
                return <ChipSuccess label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.PAID" />} />;
            }
        } else if (payment.transaction) {
            if (transaction.paymentStatus === PAYMENT_STATUS_PAID) {
                return <ChipSuccess label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.PAID" />} />;
            } else if (transaction.paymentStatus === PAYMENT_STATUS_PARTPAID) {
                return <ChipWarning label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.PARTPAID" />} />;
            } else if (transaction.paymentStatus === PAYMENT_STATUS_UNPAID) {
                return <ChipError label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.UNPAID" />} />;
            }
        } else if (payment.offerTransactions) {
            return <ChipSuccess label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.PAID" />} />;
        } else if (payment.isMarkedAsPaid) {
            return <ChipSuccess label={<FormattedMessage id="PAYMENT.PAYMENT_STATUS.PAID" />} />;
        }
    };

    const getPaymentTypeText = () => {
        if (transaction) {
            const translationValues = {
                date: moment(transaction.paidAt).format("YYYY-MM-DD HH:mm"),
            };

            if (transaction.paymentType === PAYMENT_TYPE_CARD) {
                return <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.CARD" values={translationValues} />;
            } else if (transaction.paymentType === PAYMENT_TYPE_SWISH) {
                return <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.SWISH" values={translationValues} />;
            } else if (transaction.paymentType === PAYMENT_TYPE_INVOICE) {
                return <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.INVOICE" values={translationValues} />;
            }
        } else if (payment.offerTransactions) {
            const firstOfferTransaction = payment.offerTransactions[0];
            const translationValues = {
                date: moment(firstOfferTransaction.paidAt).format("YYYY-MM-DD HH:mm"),
            };
            if (firstOfferTransaction.offerType === SERVICE_TYPE_VOUCHER) {
                return <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.VOUCHER" values={translationValues} />;
            } else if (firstOfferTransaction.offerType === SERVICE_TYPE_MEMBERSHIP) {
                return <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.MEMBERSHIP" values={translationValues} />;
            }
        }
    };

    const getPaymentAccordionDetailItems = () => {
        if (transaction) {
            return (
                <ListStyled>
                    <ListItem>
                        <ListItemIconStyled>
                            {transaction.paymentType === PAYMENT_TYPE_SWISH ? (
                                <DollarIcon />
                            ) : transaction.paymentType === PAYMENT_TYPE_INVOICE ? (
                                <InvoiceIcon />
                            ) : (
                                <CardIcon />
                            )}
                        </ListItemIconStyled>
                        <ListItemText
                            secondary={getPaymentTypeText()}
                            secondaryTypographyProps={listItemSecondaryProps}
                        ></ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemIconStyled>
                            <DownloadIcon />
                        </ListItemIconStyled>
                        <ListItemText
                            secondary={
                                <span onClick={() => downloadTransactionPdf(transaction.id, transaction.serialNumber)}>
                                    <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.DOWNLOAD" />
                                </span>
                            }
                            secondaryTypographyProps={listItemSecondaryProps}
                        ></ListItemText>
                    </ListItem>
                    {isAtLeastStaffPermission(profile.permissionId) && (
                        <>
                            <Divider />
                            <ListItem button>
                                <ListItemIconStyled>
                                    <LinkIcon />
                                </ListItemIconStyled>
                                <ListItemText
                                    secondary={
                                        <Link to={`/transactions/${transaction.id}`}>
                                            <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.GO_TO_PAYMENT" />
                                        </Link>
                                    }
                                    secondaryTypographyProps={listItemSecondaryProps}
                                ></ListItemText>
                            </ListItem>
                        </>
                    )}
                </ListStyled>
            );
        } else if (payment?.offerTransactions?.length > 0) {
            return (
                <ListStyled>
                    <ListItem>
                        <ListItemIconStyled>
                            <DollarIcon />
                        </ListItemIconStyled>
                        <ListItemText
                            secondary={getPaymentTypeText()}
                            secondaryTypographyProps={listItemSecondaryProps}
                        ></ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemIconStyled>
                            <LinkIcon />
                        </ListItemIconStyled>
                        {payment.offerTransactions[0].offerType === SERVICE_TYPE_VOUCHER ? (
                            <ListItemText
                                secondary={
                                    <Link to={`/vouchers/${payment.offerTransactions[0].offerId}`}>
                                        <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.GO_TO_VOUCHER" />
                                    </Link>
                                }
                                secondaryTypographyProps={listItemSecondaryProps}
                            ></ListItemText>
                        ) : (
                            <ListItemText
                                secondary={
                                    <Link to={`/memberships/${payment.offerTransactions[0].offerId}`}>
                                        <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.GO_TO_MEMBERSHIP" />
                                    </Link>
                                }
                                secondaryTypographyProps={listItemSecondaryProps}
                            ></ListItemText>
                        )}
                    </ListItem>
                </ListStyled>
            );
        } else if (payment?.isMarkedAsPaid) {
            return (
                <ListStyled>
                    <ListItem>
                        <ListItemIconStyled>
                            <DollarIcon />
                        </ListItemIconStyled>
                        <ListItemText
                            style={{ overflowWrap: "break-word" }}
                            secondary={
                                <FormattedMessage
                                    id="CALENDAR.APPOINTMENT.PAYMENT.MARKED_AS_PAID_BY"
                                    values={{
                                        name: `${payment.markedAsPaidBy.firstName} ${payment.markedAsPaidBy.surname}`,
                                    }}
                                />
                            }
                            secondaryTypographyProps={listItemSecondaryProps}
                        ></ListItemText>
                    </ListItem>
                </ListStyled>
            );
        } else if (paymentUrl) {
            return (
                <ListStyled>
                    <ListItem>
                        <ListItemIconStyled>
                            <LinkIcon />
                        </ListItemIconStyled>
                        <ListItemText
                            style={{ overflowWrap: "break-word" }}
                            secondary={
                                <>
                                    <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.STATUS.PAYMENT_URL" />
                                    <a href={paymentUrl}>{paymentUrl}</a>
                                </>
                            }
                            secondaryTypographyProps={listItemSecondaryProps}
                        ></ListItemText>
                    </ListItem>
                </ListStyled>
            );
        }

        return null;
    };

    const onSaveClicked = () => {
        if (selectedStartTime || selectedEndTime) {
            if (!getCurrentStartTime().isBefore(getCurrentEndTime())) {
                showErrorMessage(<FormattedMessage id="CALENDAR.ENTRY.ERROR.INVALID_DATES" />);
                return;
            }
        }

        if (selectedStartTime || selectedEndTime || changedResource?.id) {
            setShowUpdateConfirmation(true);
            return;
        }

        const values = getValues();
        updateEntryAppointment(
            entry,
            selectedStartTime,
            selectedEndTime,
            values.staffNotes,
            changedResource?.id,
            false,
            () => {
                resetStates();
            }
        );
    };

    const resetStates = () => {
        setSelectedStartTime(null);
        setSelectedEndTime(null);
        setChangedResource(null);
    };

    const listItemSecondaryProps = { variant: "body1" };
    const sectionPaperProps = { elevation: 0, variant: "outlined" };

    return (
        <div className={classes.entryDrawerContainer}>
            <DrawerHeader
                onDrawerToggle={onDrawerToggle}
                onSaveClicked={onSaveClicked}
                onDeleteClicked={() => setShowDeleteConfirmation(true)}
                showEditButtons={isAtLeastBookingPermission(profile.permissionId)}
                title={
                    entry.title ||
                    getEntryTitle(
                        entry?.type,
                        getCalendarSettings(profile.id),
                        entry?.booking?.customer,
                        entry?.service,
                        entry?.resources?.length > 0 ? entry.resources[0] : null
                    )
                }
            />
            <DrawerEntryTime
                startTime={getCurrentStartTime()}
                endTime={getCurrentEndTime()}
                setSelectedStartTime={setSelectedStartTime}
                setSelectedEndTime={setSelectedEndTime}
                allowEdit={isAtLeastBookingPermission(profile.permissionId)}
            />
            <div className={classes.detailsContainer}>
                {isDrawerLoading && (
                    <div className={classes.detailsLoadingContainer}>
                        <CircularProgress size={60} className={classes.loadingProgress} />
                    </div>
                )}

                {!isDrawerLoading && (
                    <>
                        {entry.deletedAt && (
                            <div className={classes.sectionContainer}>
                                <Alert severity="info">
                                    <FormattedMessage
                                        id="CALENDAR.ENTRY.REMOVED_BY"
                                        values={{
                                            date: moment(entry.deletedAt).format("YYYY-MM-DD HH:mm"),
                                            name: entry.deletedBy.firstName + " " + entry.deletedBy.surname,
                                        }}
                                    />
                                </Alert>
                            </div>
                        )}

                        <div className={classes.sectionContainer}>
                            <Typography className={classes.sectionLabel} variant="subtitle1">
                                <FormattedMessage id="CALENDAR.APPOINTMENT.BOOKING.SECTION_TITLE" />
                            </Typography>
                            <div>
                                <Accordion {...sectionPaperProps}>
                                    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.accordionHeaderContainer} style={{ flexGrow: 1 }}>
                                            <Typography style={{ flexGrow: 1 }}>{entry.service.name}</Typography>
                                            {getBookingStatusChip()}
                                        </div>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <ListStyled>
                                            <ListItem>
                                                <ListItemIconStyled>
                                                    <HistoryIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <FormattedMessage
                                                            id="CALENDAR.APPOINTMENT.BOOKING.CREATED_BY"
                                                            values={{
                                                                name:
                                                                    entry.booking.createdBy.firstName +
                                                                    " " +
                                                                    entry.booking.createdBy.surname,
                                                                date: moment(entry.booking.createdAt).format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                ></ListItemText>
                                            </ListItem>
                                            <Divider />
                                            <ListItem button>
                                                <ListItemIconStyled>
                                                    <LinkIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <Link to={"/bookings/" + entry.booking.id}>
                                                            <FormattedMessage id="CALENDAR.APPOINTMENT.BOOKING.GO_TO_BOOKING" />
                                                        </Link>
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                ></ListItemText>
                                            </ListItem>
                                        </ListStyled>
                                    </AccordionDetailsStyled>
                                </Accordion>
                            </div>
                        </div>

                        <div className={classes.sectionContainer}>
                            <Typography
                                className={classes.sectionLabel}
                                variant="subtitle1"
                                style={{ display: "inline-block" }}
                            >
                                <FormattedMessage id="CALENDAR.APPOINTMENT.RESOURCE.SECTION_TITLE" />
                            </Typography>
                            <div>
                                <Accordion {...sectionPaperProps}>
                                    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.accordionHeaderContainer}>
                                            <Avatar alt={bookedResource.name} src={bookedResource.avatarUrl} />
                                            <Typography className={classes.accordionIconHeaderLabel}>
                                                {bookedResource.name}
                                            </Typography>
                                        </div>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <ListStyled>
                                            {isAtLeastBookingPermission(profile.permissionId) && (
                                                <ListItem button onClick={() => setShowChangeResourceModal(true)}>
                                                    <ListItemIconStyled>
                                                        <EditIcon />
                                                    </ListItemIconStyled>
                                                    <ListItemText
                                                        secondary={
                                                            <FormattedMessage id="CALENDAR.APPOINTMENT.RESOURCE.CHANGE" />
                                                        }
                                                        secondaryTypographyProps={listItemSecondaryProps}
                                                    ></ListItemText>
                                                </ListItem>
                                            )}
                                            {isAtLeastStaffPermission(profile.permissionId) && (
                                                <>
                                                    <Divider />
                                                    <ListItem button>
                                                        <ListItemIconStyled>
                                                            <LinkIcon />
                                                        </ListItemIconStyled>
                                                        <ListItemText
                                                            secondary={
                                                                <Link to={getResourceDetailsUrl(bookedResource)}>
                                                                    <FormattedMessage id="CALENDAR.APPOINTMENT.RESOURCE.GO_TO_PROFILE" />
                                                                </Link>
                                                            }
                                                            secondaryTypographyProps={listItemSecondaryProps}
                                                        ></ListItemText>
                                                    </ListItem>
                                                </>
                                            )}
                                        </ListStyled>
                                    </AccordionDetailsStyled>
                                </Accordion>
                            </div>
                        </div>

                        <div className={classes.sectionContainer}>
                            <Typography className={classes.sectionLabel} variant="subtitle1">
                                <FormattedMessage id="CALENDAR.APPOINTMENT.CUSTOMER.SECTION_TITLE" />
                            </Typography>
                            <div>
                                <Accordion {...sectionPaperProps}>
                                    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.accordionHeaderContainer}>
                                            <Avatar
                                                alt={entry.booking.customer.firstName}
                                                src={entry.booking.customer.avatarUrl}
                                            />
                                            <Typography className={classes.accordionIconHeaderLabel}>
                                                {entry.booking.customer.firstName +
                                                    " " +
                                                    entry.booking.customer.surname}
                                            </Typography>
                                        </div>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <ListStyled component="nav" aria-label="main mailbox folders">
                                            <ListItem>
                                                <ListItemIconStyled>
                                                    <MailIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={entry.booking.customer.email}
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem>
                                                <ListItemIconStyled>
                                                    <PhoneIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        entry.booking.customer.phoneNumber || (
                                                            <FormattedMessage id="CALENDAR.APPOINTMENT.CUSTOMER.NO_PHONE" />
                                                        )
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem>
                                                <ListItemIconStyled>
                                                    <HistoryIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <FormattedMessage
                                                            id="CALENDAR.APPOINTMENT.CUSTOMER.MEMBER_SINCE"
                                                            values={{
                                                                date: moment(entry.booking.customer.createdAt).format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem button>
                                                <ListItemIconStyled>
                                                    <LinkIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <Link to={"/customers/" + entry.booking.customer.id}>
                                                            <FormattedMessage id="CALENDAR.APPOINTMENT.CUSTOMER.GO_TO_PROFILE" />
                                                        </Link>
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                ></ListItemText>
                                            </ListItem>
                                        </ListStyled>
                                    </AccordionDetailsStyled>
                                </Accordion>
                            </div>
                        </div>

                        {entry.booking.addons && (
                            <div className={classes.sectionContainer}>
                                <Typography className={classes.sectionLabel} variant="subtitle1">
                                    <FormattedMessage id="CALENDAR.APPOINTMENT.ADDONS.SECTION_TITLE" />
                                </Typography>
                                <div>
                                    {entry.booking.addons.map((addon, index) => (
                                        <Accordion
                                            {...sectionPaperProps}
                                            className={classes.addonListItem}
                                            expanded={false}
                                            key={`addon_${addon.id}`}
                                            style={{
                                                marginBottom: index < entry.booking.addons.length - 1 ? "8px" : "0px",
                                            }}
                                        >
                                            <AccordionSummaryStyled>
                                                <div
                                                    className={classes.accordionHeaderContainer}
                                                    style={{ flexGrow: 1 }}
                                                >
                                                    <Typography
                                                        style={{ flexGrow: 1 }}
                                                    >{`${addon.service.name} (x${addon.quantity})`}</Typography>
                                                    <Typography>{`${addon.price} ${addon.currency}`}</Typography>
                                                </div>
                                            </AccordionSummaryStyled>
                                        </Accordion>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className={classes.sectionContainer}>
                            <Typography className={classes.sectionLabel} variant="subtitle1">
                                <FormattedMessage id="CALENDAR.APPOINTMENT.PAYMENT.SECTION_TITLE" />
                            </Typography>
                            <div>
                                <Accordion {...sectionPaperProps}>
                                    <AccordionSummaryStyled
                                        expandIcon={!entry.booking.payment && !paymentUrl ? null : <ExpandMoreIcon />}
                                    >
                                        <div className={classes.accordionHeaderContainer} style={{ flexGrow: 1 }}>
                                            <Typography style={{ flexGrow: 1 }}>
                                                <FormattedMessage
                                                    id="CALENDAR.APPOINTMENT.PAYMENT.TITLE"
                                                    values={{
                                                        price: entry.booking.price,
                                                        currency: entry.booking.currency,
                                                    }}
                                                />
                                            </Typography>
                                            {getPaymentStatusChip()}
                                        </div>
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>{getPaymentAccordionDetailItems()}</AccordionDetailsStyled>
                                </Accordion>
                            </div>
                        </div>

                        {entry?.booking?.checkout?.customerMessage && (
                            <div className={classes.sectionContainer}>
                                <Typography className={classes.sectionLabel} variant="subtitle1">
                                    <FormattedMessage id="CALENDAR.ENTRY.CUSTOMER_MESSAGE" />
                                </Typography>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="customerMessage"
                                    defaultValue={entry.booking.checkout.customerMessage}
                                    readOnly
                                    disabled
                                />
                            </div>
                        )}

                        <form>
                            <div className={classes.sectionContainer}>
                                <Typography className={classes.sectionLabel} variant="subtitle1">
                                    <FormattedMessage id="CALENDAR.ENTRY.STAFF_NOTES" />
                                </Typography>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="staffNotes"
                                    ref={register}
                                    defaultValue={entry?.booking?.staffNotes}
                                />
                            </div>
                        </form>
                    </>
                )}
            </div>
            <UpdateEntryModal
                show={showUpdateConfirmation}
                titleLabel={<FormattedMessage id="CALENDAR.APPOINTMENT.UPDATE.TITLE" />}
                notifyLabel={
                    <FormattedMessage
                        id="CALENDAR.APPOINTMENT.UPDATE.NOTIFY"
                        values={{ customerName: entry?.booking?.customer?.firstName }}
                    />
                }
                onCloseClicked={() => setShowUpdateConfirmation(false)}
                onUpdateClicked={(confirmationValues) => {
                    setShowUpdateConfirmation(false);
                    const values = getValues();
                    updateEntryAppointment(
                        entry,
                        selectedStartTime,
                        selectedEndTime,
                        values.staffNotes,
                        changedResource?.id,
                        confirmationValues.notifyUser,
                        () => {
                            resetStates();
                        }
                    );
                }}
            />
            <DeleteEntryModal
                show={showDeleteConfirmation}
                titleLabel={<FormattedMessage id="CALENDAR.APPOINTMENT.DELETE.TITLE" />}
                notifyLabel={
                    <FormattedMessage
                        id="CALENDAR.APPOINTMENT.DELETE.NOTIFY"
                        values={{ customerName: entry?.booking?.customer?.firstName }}
                    />
                }
                messageLabel={<FormattedMessage id="CALENDAR.APPOINTMENT.DELETE.MESSAGE" />}
                messageDescription={<FormattedMessage id="CALENDAR.APPOINTMENT.DELETE.MESSAGE_DESCRIPTION" />}
                showCancellationMessage={true}
                onCloseClicked={() => setShowDeleteConfirmation(false)}
                onDeleteClicked={(values) => {
                    setShowDeleteConfirmation(false);
                    deleteEntryAppointment(entry.id, values.notifyUser, values.cancellationMessage, () => {
                        if (onDrawerEntryDeleted) {
                            onDrawerEntryDeleted();
                        }
                    });
                }}
            />
            {showChangeResourceModal && (
                <SelectResourceModal
                    show={showChangeResourceModal}
                    onCloseClicked={() => setShowChangeResourceModal(false)}
                    onSaveClicked={(resource) => {
                        setShowChangeResourceModal(false);
                        setChangedResource(resource);
                    }}
                    titleText={<FormattedMessage id={"CALENDAR.APPOINTMENT.RESOURCE.CHANGE.MODAL.TITLE"} />}
                    saveButtonText={<FormattedMessage id="CALENDAR.APPOINTMENT.RESOURCE.CHANGE.MODAL.SAVE_BUTTON" />}
                />
            )}
        </div>
    );
}

const mapDispatchToProps = {
    ...bookingManagerActions,
    ...transactionActions,
};

export default injectIntl(connect(null, mapDispatchToProps)(DrawerAppointment));
