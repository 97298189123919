import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import {
    LinkCell,
    TextCell,
    TextCellEllipsis,
    TranslationCell,
    DateCell,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import ServiceListToolbar from "../../components/tables/toolbar/ServiceListToolbar";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_MODE_MANDATORY,
    getServiceStatusCell,
    STATUS_HIDDEN,
    STATUS_DELETED,
    SERVICE_TYPE_APPOINTMENT,
    SERVICE_TYPE_COURSE,
    SERVICE_TYPE_VOUCHER,
    SERVICE_TYPE_MEMBERSHIP,
    SERVICE_TYPE_PRODUCT,
} from "./utils";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { getServiceDetailsPageUrl, getServiceCopyUrl } from "./utils";
import SnackbarError from "../../components/SnackbarError";
import { useIntl } from "react-intl";

function ServicePageList({
    getServices,
    updateServiceVisibility,
    deleteAppointment,
    deleteCourse,
    deleteMembership,
    deleteVoucher,
    deleteProduct,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");
    const [serviceToChangeVisibility, setServiceToChangeVisibility] = useState(null);
    const [serviceToDelete, setServiceToDelete] = useState(null);

    const history = useHistory();
    const intl = useIntl();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getServices(profile.id, pageIndex + 1, pageSize, search, status, type);
        },
        [getServices, profile.id, search, status, type]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onTypeChanged = useCallback((value) => {
        setType(value);
    }, []);

    return (
        <Paper>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return (
                        <ServiceListToolbar
                            onSearchChanged={onSearchChanged}
                            onStatusChanged={onStatusChanged}
                            onTypeChanged={onTypeChanged}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.ID" />,
                            accessor: "id",
                            maxWidth: 75,
                            Cell: ({ row }) => {
                                const url = getServiceDetailsPageUrl(row.original.type, row.original.id);
                                if (!url) return TextCell(row.original.id);
                                return LinkCell(url, row.original.id);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.NAME" />,
                            accessor: "name",
                            maxWidth: 275,
                            Cell: ({ row }) => {
                                return TextCellEllipsis(row.original.name);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.CATEGORY" />,
                            accessor: "category",
                            maxWidth: 200,
                            Cell: ({ row }) => {
                                if (row.original.category) {
                                    return TextCellEllipsis(row.original.category.name);
                                }
                                return <></>;
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.PRICE" />,
                            accessor: "price",
                            maxWidth: 105,
                            Cell: ({ row }) => {
                                const price = row.original.fromPrice || row.original.price;
                                let text = "";
                                if (row.original.fromPrice) {
                                    text = intl.formatMessage(
                                        {
                                            id: "SERVICE.APPOINTMENT.FROM_PRICE",
                                        },
                                        { price: price }
                                    );
                                    if (row.original.currency) {
                                        text += ` ${row.original.currency.shortName}`;
                                    }
                                } else {
                                    text = row.original.price;
                                    if (row.original.currency) {
                                        text += ` ${row.original.currency.shortName}`;
                                    }
                                }
                                return TextCell(text);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.VAT" />,
                            accessor: "vat",
                            maxWidth: 75,
                            Cell: (props) => {
                                return TextCell(`${props.value.amount}%`);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.BOOKINGS" />,
                            accessor: "activeBookings",
                            maxWidth: 110,
                            textAlign: "center",
                            Cell: ({ row }) => {
                                if (row.original.activeBookings >= 0 && row.original.totalBookings >= 0) {
                                    return TextCell(`${row.original.activeBookings}/${row.original.totalBookings}`);
                                }
                                return TextCell();
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.START_TIME" />,
                            accessor: "startTime",
                            maxWidth: 100,
                            Cell: (props) => {
                                return DateCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE.LIST.HEADER.PAYMENT_MODE" />,
                            accessor: "paymentMode",
                            Cell: ({ row }) => {
                                if (row.original.paymentMode === PAYMENT_MODE_ON_ARRIVAL) {
                                    return TranslationCell("SERVICE.PAYMENT_MODE.ON_ARRIVAL");
                                } else if (row.original.paymentMode === PAYMENT_MODE_OPTIONAL) {
                                    return TranslationCell("SERVICE.PAYMENT_MODE.OPTIONAL");
                                } else if (row.original.paymentMode === PAYMENT_MODE_MANDATORY) {
                                    return TranslationCell("SERVICE.PAYMENT_MODE.MANDATORY");
                                }
                                return TextCell("-");
                            },
                        },
                        {
                            Header: <FormattedMessage id="BOOKING.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return getServiceStatusCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                if (row.original.status === STATUS_DELETED) return <></>;
                                const isVisible = row.original.status !== STATUS_HIDDEN;
                                return (
                                    <ListActionsButton
                                        menuItems={[
                                            {
                                                icon: <LinkIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.OPEN" />,
                                                onClick: () => {
                                                    const url = getServiceDetailsPageUrl(
                                                        row.original.type,
                                                        row.original.id
                                                    );
                                                    if (url) {
                                                        history.push(url);
                                                    }
                                                },
                                            },
                                            {
                                                icon: isVisible ? (
                                                    <VisibilityOffIcon fontSize="small" />
                                                ) : (
                                                    <VisibilityIcon fontSize="small" />
                                                ),
                                                label: isVisible ? (
                                                    <FormattedMessage id="SERVICE.VISBILITY.HIDE" />
                                                ) : (
                                                    <FormattedMessage id="SERVICE.VISBILITY.SHOW" />
                                                ),
                                                onClick: () => setServiceToChangeVisibility(row.original),
                                            },
                                            {
                                                icon: <CopyIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.COPY" />,
                                                onClick: () => {
                                                    const url = getServiceCopyUrl(row.original.type, row.original.id);
                                                    if (url) {
                                                        history.push(url);
                                                    }
                                                },
                                            },
                                            {
                                                icon: <DeleteOutlinedIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.DELETE" />,
                                                onClick: () => setServiceToDelete(row.original),
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                    [history, intl]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
            <YesNoDialog
                title={<FormattedMessage id="SERVICE.DELETE.TEXT" />}
                open={serviceToDelete ? true : false}
                onOpenChanged={() => setServiceToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        if (serviceToDelete.type === SERVICE_TYPE_APPOINTMENT) {
                            deleteAppointment(serviceToDelete.id);
                        } else if (serviceToDelete.type === SERVICE_TYPE_COURSE) {
                            deleteCourse(serviceToDelete.id);
                        } else if (serviceToDelete.type === SERVICE_TYPE_MEMBERSHIP) {
                            deleteMembership(serviceToDelete.id);
                        } else if (serviceToDelete.type === SERVICE_TYPE_VOUCHER) {
                            deleteVoucher(serviceToDelete.id);
                        } else if (serviceToDelete.type === SERVICE_TYPE_PRODUCT) {
                            deleteProduct(serviceToDelete.id);
                        }
                    }
                    setServiceToDelete(null);
                }}
            />
            <YesNoDialog
                title={<FormattedMessage id="SERVICE.VISBILITY.CONFIRM.TITLE" />}
                description={
                    serviceToChangeVisibility?.status === STATUS_HIDDEN ? (
                        <FormattedMessage id="SERVICE.VISBILITY.CONFIRM.SHOW_TEXT" />
                    ) : (
                        <FormattedMessage id="SERVICE.VISBILITY.CONFIRM.HIDE_TEXT" />
                    )
                }
                open={serviceToChangeVisibility ? true : false}
                onOpenChanged={() => setServiceToChangeVisibility(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        const shouldBeVisible = serviceToChangeVisibility.status === STATUS_HIDDEN;
                        updateServiceVisibility(serviceToChangeVisibility.id, shouldBeVisible);
                    }
                    setServiceToChangeVisibility(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...serviceActions, ...errorActions })(ServicePageList);
