import React, { useEffect } from "react";
import { actions as profileActions } from "../../../redux/profiles/profilesRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { ModeSettingDropdown } from "../../components/dropdowns/ModeSettingDropdown";

const schema = yup.object().shape({
    termsAndConditions: yup.string(),
    emailDetails: yup.string(),
});

function SettingsPageBookings({ updateProfile }) {
    const { profile, isUpdating } = useSelector((state) => state.profiles);

    const intl = useIntl();

    const { register, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (profile) {
            if (profile.bookingPhoneMode) {
                setValue("bookingPhoneMode", profile.bookingPhoneMode);
            }

            if (profile.customerMessageMode) {
                setValue("customerMessageMode", profile.customerMessageMode);
            }

            if (profile.proxyUsersMode) {
                setValue("proxyUsersMode", profile.proxyUsersMode);
            }
        }
    }, [profile, setValue, intl]);

    const onFormSubmit = (values) => {
        updateProfile(profile.id, profile, values);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROFILE.BOOKING_SETTINGS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROFILE.BOOKING_SETTINGS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.BOOKING_PHONE_MODE" />
                            </Form.Label>
                            <ModeSettingDropdown name={"bookingPhoneMode"} register={register} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.CUSTOMER_MESSAGE_MODE" />
                            </Form.Label>
                            <ModeSettingDropdown name={"customerMessageMode"} register={register} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.PROXY_USERS_MODE" />
                            </Form.Label>
                            <ModeSettingDropdown name={"proxyUsersMode"} register={register} />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="SERVICE.PAYMENT.PROXY_USERS_MODE_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.TERMS_AND_CONDITIONS" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                className="form-control-lg"
                                name="termsAndConditions"
                                ref={register}
                                defaultValue={profile?.termsAndConditions}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.TERMS_AND_CONDITIONS.DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.EMAIL_DETAILS" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                className="form-control-lg"
                                name="emailDetails"
                                ref={register}
                                defaultValue={profile?.emailDetails}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="PROFILE.BOOKING_SETTINGS.EMAIL_DETAILS.DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, profileActions)(SettingsPageBookings);
