import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as profileActions } from "../../../redux/profiles/profilesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import SettingsPageNavigator from "./SettingsPageNavigator";
import SettingsPageDetails from "./SettingsPageDetails";
import SettingsPageBookings from "./SettingsPageBookings";
import SettingsPageBillingTemplates from "./SettingsPageBillingTemplates";
import SettingsPagePricingTemplates from "./SettingsPagePricingTemplates";
import SettingsPageCancellationTemplates from "./SettingsPageCancellationTemplates";
import SettingsPageReminderTemplates from "./SettingsPageReminderTemplates";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function SettingsPage({ getProfile, clearProfileState, clearDisplayError, clearErrorState }) {
    const { profile: authProfile } = useSelector((state) => state.auth);
    const { profile, isLoading, isUpdating } = useSelector((state) => state.profiles);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const profileId = authProfile.id;
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!profile || profile.id !== profileId)) {
            getProfile(profileId);
        }
    }, [getProfile, profile, profileId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearProfileState();
            clearErrorState();
        };
    }, [clearProfileState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <SettingsPageNavigator />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect from={`/settings`} exact={true} to={`/settings/details`} />
                        <Route path={`/settings/details`} component={SettingsPageDetails} />
                        <Route path={`/settings/bookings`} component={SettingsPageBookings} />
                        <Route path={`/settings/billing-templates`} component={SettingsPageBillingTemplates} />
                        <Route path={`/settings/pricing-templates`} component={SettingsPagePricingTemplates} />
                        <Route path={`/settings/reminder-templates`} component={SettingsPageReminderTemplates} />
                        <Route
                            path={`/settings/cancellation-templates`}
                            component={SettingsPageCancellationTemplates}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...profileActions, ...errorActions })(SettingsPage);
