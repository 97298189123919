import React, { useState, useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    TextCell,
    TranslationCell,
    AvatarCell,
    LinkCell,
    DeleteActionButton,
} from "../../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";
import AppointmentResourceAddModal from "./AppointmentResourceAddModal";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import AppointmentResourceListToolbar from "../../../components/tables/toolbar/AppointmentResourceListToolbar";
import { RESOURCE_STAFF_TYPE, RESOURCE_SPACE_TYPE, RESOURCE_ARTICLE_TYPE } from "../../resources/utils";

function AppointmentPageResources({ getAppointmentResources, addAppointmentResource, deleteAppointmentResource }) {
    const { appointment, appointmentResourcesPagination, isLoading, isUpdating } = useSelector(
        (state) => state.services
    );
    const serviceId = appointment?.id;

    const [status, setStatus] = useState("active");

    const [showModalAddResource, setShowModalAddResource] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getAppointmentResources(serviceId, pageIndex + 1, pageSize, status);
            }
        },
        [serviceId, getAppointmentResources, status]
    );

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.SUBTITLE" />
                        </span>
                    </div>
                    {appointment && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddResource(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => appointmentResourcesPagination.data, [appointmentResourcesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={appointmentResourcesPagination.totalRows}
                        isLoading={isLoading}
                        renderToolbar={() => {
                            return <AppointmentResourceListToolbar onStatusChanged={onStatusChanged} />;
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.TYPE" />,
                                    accessor: "type",
                                    maxWidth: 100,
                                    Cell: ({ row }) => {
                                        const type = row.original.type;
                                        if (type === "resource") {
                                            const resourceType = row.original.resource.type;
                                            if (resourceType === RESOURCE_STAFF_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.STAFF.STAFF");
                                            } else if (resourceType === RESOURCE_SPACE_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.SPACE");
                                            } else if (resourceType === RESOURCE_ARTICLE_TYPE) {
                                                return TranslationCell("RESOURCE.TYPE.ARTICLE");
                                            }
                                        } else if (type === "resource_group") {
                                            return TranslationCell(
                                                "SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.TYPE.GROUP"
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.NAME" />,
                                    accessor: "resource",
                                    Cell: ({ row }) => {
                                        if (row.original.resource) {
                                            const resource = row.original.resource;
                                            return (
                                                <Link to={`/resources/${resource.id}/${resource.type}`}>
                                                    {AvatarCell(resource.avatarUrl, `${resource.name}`)}
                                                </Link>
                                            );
                                        } else if (row.original.resourceGroup) {
                                            const resourceGroup = row.original.resourceGroup;
                                            return LinkCell(`/resource-groups/${resourceGroup.id}`, resourceGroup.name);
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.LIST.HEADER.BOOKING_COUNT" />
                                    ),
                                    accessor: "bookingCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "delete",
                                    Cell: ({ row }) => {
                                        const intl = useIntl();
                                        if (row.original.deletedAt) return <></>;

                                        const serviceResourceId = row.original.id;
                                        var title;
                                        if (row.original.resource) {
                                            title = intl.formatMessage(
                                                {
                                                    id: "SERVICE.APPOINTMENT.RESOURCES.DELETE.TITLE",
                                                },
                                                { name: row.original.resource.firstName }
                                            );
                                        } else if (row.original.resourceGroup) {
                                            title = intl.formatMessage(
                                                {
                                                    id: "SERVICE.APPOINTMENT.RESOURCES.DELETE.TITLE",
                                                },
                                                { name: row.original.resourceGroup.name }
                                            );
                                        }

                                        return DeleteActionButton(
                                            title,
                                            <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.DELETE.TEXT" />,
                                            () => deleteAppointmentResource(serviceId, serviceResourceId)
                                        );
                                    },
                                },
                            ],
                            [deleteAppointmentResource, serviceId]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showModalAddResource && (
                <AppointmentResourceAddModal
                    show={showModalAddResource}
                    onCloseClicked={() => setShowModalAddResource(false)}
                    onResourceAdded={(data) => {
                        addAppointmentResource(serviceId, data, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                        });
                        setShowModalAddResource(false);
                    }}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(AppointmentPageResources);
