import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form, Col, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useFormContext, Controller } from "react-hook-form";
import { SERVICE_COLOR_DEFAULT } from "./utils";
import SelectColorModal from "../../components/SelectColorModal";
import { getRGBACssFromARGBHex } from "../../utils/ColorUtils";
import AddServiceCategoryModal from "./AddServiceCategoryModal";

function getServiceCategoriesOptions(serviceCategoriesPagination) {
    let options = [];
    if (!serviceCategoriesPagination?.data?.length) return options;
    serviceCategoriesPagination.data.forEach((serviceCategory) => {
        options.push(getServiceCategoryOption(serviceCategory));
    });

    return options;
}

function getServiceCategoryOption(serviceCategory) {
    return {
        id: serviceCategory.id,
        name: serviceCategory.name,
    };
}

function ServiceCommonDetailsForm({ getServiceCategories, service, isAddingService }) {
    const intl = useIntl();

    const { register, control, setValue, watch, errors } = useFormContext();

    const { profile } = useSelector((state) => state.auth);
    const { listPagination: serviceCategoriesListPagination, isLoading: isServiceCategoriesLoading } = useSelector(
        (state) => state.serviceCategories
    );

    const profileId = profile.id;

    const [showAddServiceCategory, setShowAddServiceCategory] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        getServiceCategories(profileId, 1, 30);
    }, [profileId, getServiceCategories]);

    useEffect(() => {
        if (service) {
            if (service.category) {
                setValue("category", service.category);
            }

            if (service.color) {
                setValue("color", service.color);
            }
        }
    }, [service, setValue]);

    const onServiceCategorySearch = (search) => {
        getServiceCategories(profileId, 1, 30, search);
    };

    const onServiceCategoryChanged = (serviceCategories) => {
        if (serviceCategories && serviceCategories.length > 0) {
            setValue("category", serviceCategories[0]);
        } else {
            setValue("category", null);
        }
    };

    const onServiceCategoryAdded = (serviceCategory) => {
        setValue("category", getServiceCategoryOption(serviceCategory));
        setShowAddServiceCategory(false);
    };

    const category = watch("category");

    register("color");
    const serviceColorHex = watch("color") || SERVICE_COLOR_DEFAULT;

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-6"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={service?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.DETAILS.DESCRIPTION" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    className="form-control form-control-lg col-xl-8"
                    name="description"
                    ref={register}
                    isInvalid={errors.description}
                    defaultValue={service?.description}
                />
                <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.DETAILS.SERVICE_CATEGORY" />
                </Form.Label>
                <Form.Row>
                    <Col xs={7} sm={4}>
                        <Controller
                            control={control}
                            name="category"
                            defaultValue={null}
                            render={() => (
                                <AsyncTypeahead
                                    id="typeahead-service-categories"
                                    labelKey={"name"}
                                    minLength={0}
                                    clearButton={true}
                                    onChange={onServiceCategoryChanged}
                                    onSearch={onServiceCategorySearch}
                                    onInputChange={(input) => {
                                        if (!input) {
                                            onServiceCategorySearch(input);
                                        }
                                    }}
                                    useCache={false}
                                    isLoading={isServiceCategoriesLoading}
                                    options={getServiceCategoriesOptions(serviceCategoriesListPagination)}
                                    placeholder={intl.formatMessage({
                                        id: "SERVICE.DETAILS.SERVICE_CATEGORY.PLACEHOLDER",
                                    })}
                                    emptyLabel={intl.formatMessage({
                                        id: "SERVICE.DETAILS.SERVICE_CATEGORY.SEARCH_EMPTY",
                                    })}
                                    selected={category ? [category] : []}
                                    filterBy={() => true}
                                />
                            )}
                        />
                    </Col>
                    <Col xs={5} sm={2}>
                        <Button variant="outline-primary" onClick={() => setShowAddServiceCategory(true)}>
                            <FormattedMessage id="SERVICE.DETAILS.SERVICE_CATEGORY.ADD_BUTTON" />
                        </Button>
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.DETAILS.COLOR" />
                </Form.Label>
                {(isAddingService || (service && serviceColorHex)) && (
                    <div
                        style={{
                            backgroundColor: `${getRGBACssFromARGBHex(serviceColorHex)}`,
                            width: 150,
                            height: 44,
                            borderRadius: "0.42rem",
                        }}
                        onClick={() => setShowColorPicker(true)}
                    ></div>
                )}
            </Form.Group>
            {showAddServiceCategory && (
                <AddServiceCategoryModal
                    show={showAddServiceCategory}
                    profileId={profileId}
                    onCloseClicked={() => setShowAddServiceCategory(false)}
                    onServiceCategoryAdded={(serviceCategory) => onServiceCategoryAdded(serviceCategory)}
                />
            )}

            {showColorPicker && (
                <SelectColorModal
                    show={showColorPicker}
                    onSaveClicked={(colorARBGHex) => {
                        setShowColorPicker(false);
                        setValue("color", colorARBGHex);
                    }}
                    onCloseClicked={() => setShowColorPicker(false)}
                    defaultColorHex={serviceColorHex}
                />
            )}
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(ServiceCommonDetailsForm);
