import React, { useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
    APPOINTMENT_DURATION_TYPE_FIXED,
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
    APPOINTMENT_DURATION_TYPE_DAYS,
    PRICE_PER_UNIT_MINUTE,
    PRICE_PER_UNIT_HOUR,
    PRICE_PER_UNIT_DAY,
} from "../utils";

const schema = yup.object().shape({
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    pricePerUnit: yup.object(),
    vat: yup.object(),
    currency: yup.object(),
    pricingTemplate: yup.object(),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    emailDetails: yup.string(),
});

function AppointmentPageCheckoutSettings({ updateAppointment, getServiceOptions }) {
    const { profile } = useSelector((state) => state.auth);
    const { appointment, serviceOptions, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profile.id);
        }
    }, [profile.id, serviceOptions, getServiceOptions]);

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalAppointment = { ...appointment };
        originalAppointment.currencyId = originalAppointment?.currency?.id;
        originalAppointment.vatId = originalAppointment?.vat?.id;
        originalAppointment.pricingTemplateId = originalAppointment?.pricingTemplate?.id || null;
        originalAppointment.billingTemplateId = originalAppointment?.billingTemplate?.id || null;
        originalAppointment.isSwishEnabled = originalAppointment.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalAppointment.isCardEnabled = originalAppointment.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalAppointment.isInvoiceEnabled = originalAppointment.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedAppointmentData = { ...appointment };
        updatedAppointmentData.paymentMode = values.paymentMode;
        updatedAppointmentData.price = values.price;
        updatedAppointmentData.pricePerUnit = values?.pricePerUnit?.id;
        updatedAppointmentData.currencyId = values?.currency?.id;
        updatedAppointmentData.vatId = values?.vat?.id;
        updatedAppointmentData.pricingTemplateId = values?.pricingTemplate?.id;
        updatedAppointmentData.billingTemplateId = values?.billingTemplate?.id;
        updatedAppointmentData.customerMessageMode = values.customerMessageMode || null;
        updatedAppointmentData.customerMessage = values.customerMessage;
        updatedAppointmentData.addonsMode = values.addonsMode || null;
        updatedAppointmentData.proxyUsersMode = values.proxyUsersMode || null;
        updatedAppointmentData.emailDetails = values.emailDetails;
        updatedAppointmentData.subscriptionRecurringUnit = values.subscriptionRecurringUnit?.id;
        updatedAppointmentData.subscriptionRecurringDayMode = values.subscriptionRecurringDayMode?.id;
        updatedAppointmentData.subscriptionRecurringDayOfMonth = values.subscriptionRecurringDayOfMonth;
        updatedAppointmentData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;

        if (values.paymentMethods) {
            updatedAppointmentData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            updatedAppointmentData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            updatedAppointmentData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        updateAppointment(appointment.id, originalAppointment, updatedAppointmentData);
    };

    const durationType = appointment?.durationType;

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.APPOINTMENT.PAYMENT_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {appointment && !appointment?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceCheckoutDetailsForm
                                serviceOptions={serviceOptions}
                                service={appointment}
                                pricePerOccasion={false}
                                pricePerUnits={
                                    durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE ||
                                    durationType === APPOINTMENT_DURATION_TYPE_DAYS
                                        ? [PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR, PRICE_PER_UNIT_DAY]
                                        : []
                                }
                                defaultPricePerUnit={
                                    durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE
                                        ? PRICE_PER_UNIT_HOUR
                                        : durationType === APPOINTMENT_DURATION_TYPE_DAYS
                                        ? PRICE_PER_UNIT_DAY
                                        : null
                                }
                                allowSubscription={appointment?.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                forceSubscription={appointment?.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                allowPricingTemplates={
                                    appointment?.durationType === APPOINTMENT_DURATION_TYPE_FIXED ||
                                    appointment?.durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE
                                }
                            />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(AppointmentPageCheckoutSettings);
